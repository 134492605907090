import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { AppState } from "../../interfaces/general/app-state";
import { User } from "../../interfaces/User";

interface Props {
  title: string;
  text: string;
  statusCode?: number;
  link?: string;
  onClick?: () => any;
  linkText: string;
}

const ErrorPage: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();
  const user = useSelector<AppState, User>((state) => state.user);

  return (
    <Box
      sx={{
        textAlign: "center",
        position: user && user.id ? "relative" : "absolute",
        top: user && user.id ? "auto" : "40%",
        left: user && user.id ? "auto" : "50%",
        transform: user && user.id ? "none" : "translate(-50%, -50%)",
        maxWidth: "80%",
        mt: user && user.id ? 5 : 0,
        marginLeft: "auto",
        marginRight: "auto"
      }}
    >
      <img src="/public/img/error.svg" alt="Fehler auf Seite" style={{ width: 300 }} />
      <Typography variant="h4" mt={3}>
        {props.statusCode ? <strong>{props.statusCode} - </strong> : null} {props.title}
      </Typography>
      <Typography variant="body1" sx={{mt:1}}>{props.text}</Typography>
      <Box marginTop={2} />
      {props.link ? (
        <Button color="secondary" component={Link} to={props.link}>
          {props.linkText}
        </Button>
      ) : (
        <Button color="secondary" onClick={props.onClick}>
          {props.linkText}
        </Button>
      )}
    </Box>
  );
};

export default ErrorPage;

export interface BexioUser {
  [key: string]: any;
  firstName: string;
  lastName: string;
  email: string;
  companyName: string;
}

export const defaultBexioUser: BexioUser = {
  firstName: "",
  lastName: "",
  email: "",
  companyName: "",
};

import * as React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { ReactNode, useContext } from "react";
import { useSelector } from "react-redux";
import { AppState } from "../../interfaces/general/app-state";
import { User } from "../../interfaces/User";
import { Request } from "../../interfaces/general/request";
import SuspenseScreen from "../../containers/SuspenseScreen";
import BexioUserContext from "../../contexts/BexioUserContext";
import ErrorPage from "../../containers/error-page/ErrorPage";
import { useTranslation } from "react-i18next";
import * as ClientBexioAuthorizationActions from "../../client/bexio/authorization";
import { useSnackbar } from "notistack";

interface ProtectedRouteProps {
  isAdminOnly?: boolean;
  isBexioOnly?: boolean;
  children?: ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ isAdminOnly = false, isBexioOnly = false, children }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = React.useState(true);
  const user = useSelector<AppState, User>((state) => state.user);
  const requests = useSelector<AppState, Request[]>((state) => state.requests);
  const { bexioUser } = useContext(BexioUserContext);
  const currentUrlEncoded = location.pathname != "/login/" ? encodeURIComponent(location.pathname) : "";

  const authorizeBexio = () => {
    ClientBexioAuthorizationActions.authorize().then(
      (response) => {
        if (response && response.data) {
          localStorage.setItem("returnUrl", location.pathname);
          window.location.href = response.data;
        }
      },
      (error) => {
        enqueueSnackbar(error.response.data, { variant: "error" });
      }
    );
  };

  React.useEffect(() => {
    var userRequestString =
      requests.filter((x) => x.type === "user/GET_CURRENT").length > 0
        ? requests.filter((x) => x.type === "user/GET_CURRENT")[0].status
        : "REQUEST";
    setIsLoading(userRequestString === "REQUEST");
  }, [requests]);

  // show suspense screen while waiting for user to authenticate
  if (isLoading) {
    return <SuspenseScreen />;
  }

  // redirect to login screen if user isn't singed in
  if (!user || user.id === "") {
    let redirectUrl = location.pathname != "/" ? currentUrlEncoded : "";
    return <Navigate to={"/login/" + redirectUrl} replace />;
  }

  if (isAdminOnly && user.roleName?.toLowerCase() !== "administrator") {
    return (
      <ErrorPage
        title={t("errorPage.notAuthorized.title")}
        text={t("errorPage.notAuthorized.text")}
        statusCode={403}
        linkText={t("errorPage.notAuthorized.link")}
        link="/"
      />
    );
  }

  if (isBexioOnly && (!bexioUser || !bexioUser.companyName)) {
    return (
      <ErrorPage
        title={t("errorPage.bexioLoginRequired.title")}
        text={t("errorPage.bexioLoginRequired.text")}
        linkText={t("errorPage.bexioLoginRequired.link")}
        onClick={authorizeBexio}
      />
    );
  }

  return children ? <>{children}</> : <Outlet />;
};

export default ProtectedRoute;

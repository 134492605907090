import axios, { AxiosResponse } from "axios";
import { BexioUser } from "../../interfaces/bexio/BexioUser";

export const authorize = () : Promise<AxiosResponse<string>> => {
    return axios.post("/bexio/authorization/authorize");
};

export const disconnect = () : Promise<AxiosResponse<string>> => {
    return axios.post("/bexio/authorization/disconnect");
}

export const getAccessToken = (code : string) : Promise<AxiosResponse<string>> => {
    return axios.get(`bexio/authorization/getAccessToken?code=${code}`)
}

export const getCurrentBexioUser = () : Promise<AxiosResponse<BexioUser>> => {
    return axios.get("bexio/authorization/getCurrentBexioUser");
}
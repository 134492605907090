import React from "react";
import { BexioUser } from "../interfaces/bexio/BexioUser";

interface BexioUserContextType {
  bexioUser: BexioUser | null;
  setBexioUser: (bexioUser: BexioUser | null) => void;
}

const BexioUserContext = React.createContext<BexioUserContextType>(null);

export default BexioUserContext;

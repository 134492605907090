import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { Route, Routes as RouteWrapper } from "react-router-dom";
import * as loadable from "react-loadable";
import ProtectedRoute from "./components/_global/ProtectedRoute";
import ErrorPage from "./containers/error-page/ErrorPage";
import { useTranslation } from "react-i18next";

const AsyncHomeContainer = loadable({
  loader: () => import(/* webpackChunkName: "Home" */ "./containers/home/Home"),
  loading: () => <CircularProgress />,
});
const AsyncDashboardContainer = loadable({
  loader: () => import(/* webpackChunkName: "Dashboard" */ "./containers/dashboard/Dashboard"),
  loading: () => <CircularProgress />,
});
const AsyncLoginContainer = loadable({
  loader: () => import(/* webpackChunkName: "Login" */ "./containers/authentication/Login"),
  loading: () => <CircularProgress />,
});
const AsyncLogoutContainer = loadable({
  loader: () => import(/* webpackChunkName: "Logout" */ "./containers/authentication/Logout"),
  loading: () => <CircularProgress />,
});
const AsyncForgotPasswordContainer = loadable({
  loader: () => import(/* webpackChunkName: "ForgotPassword" */ "./containers/authentication/ForgotPassword"),
  loading: () => <CircularProgress />,
});
const AsyncResetPasswordContainer = loadable({
  loader: () => import(/* webpackChunkName: "ResetPassword" */ "./containers/authentication/ResetPassword"),
  loading: () => <CircularProgress />,
});
const AsyncProfileContainer = loadable({
  loader: () => import(/* webpackChunkName: "Profile" */ "./containers/profile/Profile"),
  loading: () => <CircularProgress />,
});
const AsyncUserManagementContainer = loadable({
  loader: () => import(/* webpackChunkName: "UserManagement" */ "./containers/user-management/UserManagement"),
  loading: () => <CircularProgress />,
});
const AsyncImportOverviewContainer = loadable({
  loader: () => import(/* webpackChunkName: "ImportOverview" */ "./containers/import/ImportOverview"),
  loading: () => <CircularProgress />,
});
const AsyncImportDetailContainer = loadable({
  loader: () => import(/* webpackChunkName: "ImportDetail" */ "./containers/import/ImportDetail"),
  loading: () => <CircularProgress />,
});
const AsyncBexioAuthorizationRedirect = loadable({
  loader: () =>
    import(
      /* webpackChunkName: "BexioAuthorizationRedirect" */ "./containers/authentication/BexioAuthorizationRedirect"
    ),
  loading: () => <CircularProgress />,
});

const Routes: React.FC = () => {
  const {t} = useTranslation();

  return (
    <RouteWrapper>
      <Route path="/" element={<AsyncHomeContainer />} />
      <Route path="login" element={<AsyncLoginContainer />}>
        <Route path=":url" element={<AsyncLoginContainer />}>
          <Route path=":key" element={<AsyncLoginContainer />} />
        </Route>
      </Route>
      <Route path="/logout" element={<AsyncLogoutContainer />} />
      <Route path="/forgot-password" element={<AsyncForgotPasswordContainer />} />
      <Route path="/reset-password" element={<AsyncResetPasswordContainer />} />
      <Route path="/auth/confirm" element={<AsyncBexioAuthorizationRedirect />} />

      {/* standard protected routes */}
      <Route element={<ProtectedRoute />}>
        <Route path="profile" element={<AsyncProfileContainer />} />
        <Route path="dashboard" element={<AsyncDashboardContainer />} />
      </Route>

      {/* bexio only routes */}
      <Route element={<ProtectedRoute isBexioOnly />}>
        <Route path="imports" element={<AsyncImportOverviewContainer />} />
        <Route path="imports/:id" element={<AsyncImportDetailContainer />} />
      </Route>

      {/* admin protected routes */}
      <Route element={<ProtectedRoute isAdminOnly />}>
        <Route path="users" element={<AsyncUserManagementContainer />} />
      </Route>

      <Route
        path="*"
        element={
          <ErrorPage
            title={t("errorPage.notFound.title")}
            text={t("errorPage.notFound.text")}
            statusCode={404}
            linkText={t("errorPage.notFound.link")}
            link="/"
          />
        }
      />
    </RouteWrapper>
  );
};

export default Routes;

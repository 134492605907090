import axios, { AxiosResponse } from "axios";
import { User } from "../interfaces/User";
import { PaginatedList } from "../interfaces/general/PaginatedList";

export const getAll = (page: number, rowsPerPage: number) :  Promise<AxiosResponse<PaginatedList<User>>> => {
    return axios.get(`/users/getAll?page=${page}&rowsPerPage=${rowsPerPage}`);
}

export const getCurrent = () :  Promise<AxiosResponse<User>> => {
    return axios.get("/users/getCurrent");
}

export const updateCurrent = (user : User) :  Promise<AxiosResponse<string>>=> {
    return axios.post("/users/updateCurrent", user);
}
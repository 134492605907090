import { ListItem, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, Paper, Typography } from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import * as ClientBexioAuthorizationActions from "../../client/bexio/authorization";
import BexioUserContext from "../../contexts/BexioUserContext";
import { useLocation, useMatch } from "react-router-dom";
import { useSnackbar } from "notistack";

const BexioAuthListItem: React.FC = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { t } = useTranslation();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { bexioUser, setBexioUser } = useContext(BexioUserContext);
  const open = Boolean(anchorEl);

  const openMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const authorizeBexio = () => {
    ClientBexioAuthorizationActions.authorize().then(
      (response) => {
        if (response && response.data) {
          localStorage.setItem("returnUrl", location.pathname);
          window.location.href = response.data;
        }
      },
      (error) => {
        enqueueSnackbar(error.response.data, { variant: "error" });
      }
    );
  };

  const disconnectBexio = () => {
    ClientBexioAuthorizationActions.disconnect().then(
      (response) => {
        if (response && response.data) {
          enqueueSnackbar(response.data, { variant: "success" });
          setBexioUser(null);
          closeMenu();
        }
      },
      (error) => {
        enqueueSnackbar(error.response.data, { variant: "error" });
      }
    );
  };

  return (
    <>
      <ListItem disablePadding>
        <ListItemButton
          sx={{
            paddingLeft: "30px",
          }}
          onClick={openMenu}
        >
          <ListItemIcon sx={{ minWidth: "45px" }}>
            <img src="/public/img/bexio.png" style={{ width: "35px" }} />
          </ListItemIcon>
          <ListItemText
            primary={bexioUser && bexioUser.firstName ? bexioUser.firstName + " " + bexioUser.lastName : t("navigation.bexioSettings.title")}
            secondary={bexioUser && bexioUser.companyName ? bexioUser.companyName : ""}
          />
        </ListItemButton>
      </ListItem>
      <Paper sx={{ width: 320, maxWidth: "100%" }}>
        <Menu open={open} anchorEl={anchorEl} onClose={closeMenu} anchorOrigin={{ vertical: "top", horizontal: "right" }}>
          {bexioUser && bexioUser.email ? (
            [
              <MenuItem onClick={authorizeBexio} key="navitem-2">
                <ListItemIcon>
                  <SwapHorizIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>{t("navigation.bexioSettings.changeCompanyProfile")}</ListItemText>
              </MenuItem>,
              <MenuItem onClick={disconnectBexio} key="navitem-1">
                <ListItemIcon>
                  <LinkOffIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>{t("navigation.bexioSettings.disconnect")}</ListItemText>
              </MenuItem>,
            ]
          ) : (
            <MenuItem onClick={authorizeBexio}>
              <ListItemIcon>
                <LinkIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>{t("navigation.bexioSettings.connect")}</ListItemText>
            </MenuItem>
          )}
        </Menu>
      </Paper>
    </>
  );
};

export default BexioAuthListItem;

import { ListItem, ListItemButton, ListItemIcon, ListItemText, useTheme } from "@mui/material";
import React from "react";
import { NavLink, useMatch } from "react-router-dom";

interface Props {
  to: string;
  icon: React.ReactNode;
  listItemText: string;
  altMatchPath?: string; // alternativer path der ebenfalls als path matching akzeptiert wird
}

const CustomListItem: React.FC<Props> = (props: Props) => {
  const theme = useTheme();
  const match = useMatch(props.to + "/*");

  return (
    <ListItem disablePadding>
      <ListItemButton
        component={NavLink}
        to={props.to}
        sx={{
          paddingLeft: "30px",
          "& span": { lineHeight: 2.5, fontWeight: match ? "bold" : undefined }
        }}
      >
        <ListItemIcon sx={{minWidth: "45px", color: match ? theme.palette.primary.main : undefined}}>
          {props.icon}
        </ListItemIcon>
        <ListItemText primary={props.listItemText} />
      </ListItemButton>
    </ListItem>
  );
};

export default CustomListItem;

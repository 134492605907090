export interface User {
    [key: string]: any;
    id: string;
    email: string;
    userName: string;
    firstName: string;
    lastName: string;
    roleName: string;
    languageId: number;
    languageShortName: string;
  }

  export const defaultUser : User = {
      id: "",
      email: "",
      userName: "",
      firstName: "",
      lastName: "",
      roleName: "",
      languageId: 0,
      languageShortName: "",
  }